import React from "react"
import Fade from "react-reveal/Fade"
import data from "../yourdata"

import { StaticImage } from "gatsby-plugin-image"
export function Dino() {
  return <StaticImage src="../images/daily-task-manager.png" alt="Project photo 1" />
}



const About = () => {
  return (
    <div className="secion" id="about">
      <div className="container">
        <div className="about-section">
          <div className="content">
            <Fade bottom cascade>
              <h1>About Me</h1>
            </Fade>
            <p>
              {data.aboutParaOne}
              <br></br>
              <br></br>
              {data.aboutParaTwo}
              <br></br>
              <br></br>
              {data.aboutParaThree}
            </p>
          </div>
          <div className="image-wrapper">
            <img src={data.aboutImage} alt="about"></img>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
